import React, { Fragment, ReactNode } from "react";
import { useStore } from "../../app/stores/store";
import router from "router";

interface GuestGuardProps {
  children: ReactNode;
}

// this handles the initial redirection
const GuestGuard = ({ children }: GuestGuardProps) => {
  const {
    currentUserStore: { isLoggedIn, currentUser },
  } = useStore();
  if (isLoggedIn && (currentUser?.roleId == "admin" || currentUser?.roleId == "editor" || currentUser?.roleId == "root"))
    router.navigate("/facilities");
  if (isLoggedIn && (currentUser?.roleId == "viewer" || currentUser?.roleId == "root")) router.navigate("/card-viewer", { replace: true });
  return <Fragment>{children}</Fragment>;
};

export default GuestGuard;
