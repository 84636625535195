import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { CreateItemAnnotation, Item, ItemAsset, UpdateItemAnnotation, UpdateItemPart } from "../models/item";
import { ItemSearchParams, SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";
import { AddItemPartRequest, ItemPart, ItemPartMapping, UpdateItemPartRequest } from "app/models/itemPart";
import { ShapeProp } from "components/ImageAnnotator/types";

export default class ItemStore {
  items: Item[] = [];
  currentEditId: string | null = "";
  selectedItem?: Item | null = null;
  itemAssets: ItemAsset[] | null = null;
  itemParts: ItemPart[] | null = null;
  itemMetaData: Omit<PaginatedResult<Item>, "data"> | null = null;
  shapeProps: ShapeProp[] = [];

  loading = false;
  loadingInitial = false;
  loadingAllItems = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.currentEditId,
      (currentEditId) => {
        this.selectedItem = null;
        this.itemParts = null;
        this.itemAssets = null;
      }
    );
  }

  setCurrentEditId(id: string) {
    if (this.currentEditId !== id) {
      this.resetItem();
      this.currentEditId = id;
    }
  }

  resetItem = () => {
    runInAction(() => {
      this.currentEditId = "";
      this.selectedItem = null;
      this.itemParts = null;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  setLoadingAllItems = (state: boolean) => {
    runInAction(() => {
      this.loadingAllItems = state;
    });
  };

  setItemMetaData = (metaData: Omit<PaginatedResult<Item>, "data">) => {
    runInAction(() => {
      this.itemMetaData = metaData;
    });
  };
  setSelectedItem = (state: Item) => {
    runInAction(() => {
      this.selectedItem = state;
    });
  };

  loadItems = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined,
    partFamilyId: string = ""
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: ItemSearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
        partFamilyId,
      };
      const { data, ...metaData } = await agent.Items.search(params);
      runInAction(() => {
        this.items = data;
      });
      this.setItemMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadAllItems = async () => {
    this.setLoadingAllItems(true);
    try {
      const response = await agent.Items.list();
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.items = response.data;
      });
      this.setLoadingAllItems(false);
    } catch (error) {
      console.log(error);
      this.setLoadingAllItems(false);
      throw error;
    }
  };

  get itemsSorted() {
    return Array.from(this.items.values()).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  setItemAssets = (state: ItemAsset[]) => {
    runInAction(() => {
      this.itemAssets = state;
    });
  };

  loadAssetsForItem = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.getAssets(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.itemAssets = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  createItem = async (item: Item) => {
    this.setLoading(true);
    try {
      const itemRequestBody = {
        id: item.id,
        name: item.name,
        codeName: item.codeName,
        description: item.description,
        itemNumber: item.itemNumber,
        imageFile: item.imageFile,
        imageUrl: item.imageUrl,
        partFamilyId: item.partFamilyId,
      };
      const response = await agent.Items.create(itemRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  updateItem = async (item: Item) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.update(item);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  deleteItem = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  removeSelectedItem = async () => {
    this.setLoadingInitial(true);
    try {
      runInAction(() => {
        this.selectedItem = undefined;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadInitialData = async () => {
    if (!this.currentEditId) throw new Error("Item Not Selected");
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.get(this.currentEditId);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.selectedItem = response.data;
      });

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadItem = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.get(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.selectedItem = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  createPart = async (item: AddItemPartRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.createPart(item);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  updatePart = async (item: UpdateItemPartRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.updateFullPart(item);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  deletePart = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.deletePart(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  updateItemPartMapping = async (item: UpdateItemPart) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.updatePart(item);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  loadParts = async (id: string) => {
    try {
      const response = await agent.Items.getParts(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.itemParts = response.data;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resetItemParts = () => {
    runInAction(() => {
      this.itemParts = null;
    });
  };
  setShapeProps = (state: ShapeProp[] | []) => {
    runInAction(() => {
      this.shapeProps = state;
    });
  };
  getItemPartAnnotations = () => {
    const shapes: ShapeProp[] = [];

    if (this?.selectedItem && this?.selectedItem?.parts) {
      this.selectedItem.parts.forEach((fl) => {
        if (fl?.mappingStyle) {
          shapes.push({ ...JSON.parse(fl.mappingStyle), id: fl.id, mappingId: fl.id, annotationStorageType: "itemPart" });
        }
      });
    }
    if (this?.selectedItem && this.selectedItem?.annotations) {
      this.selectedItem.annotations.forEach((bm) => {
        shapes.push({ ...JSON.parse(bm.mapping), id: bm.id, mappingId: bm.id, annotationStorageType: "item" });
      });
    }
    return shapes;
  };

  updateItemAnnotation = async (itemAnnotation: UpdateItemAnnotation) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.updateAnnotation(itemAnnotation);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  createItemAnnotation = async (itemAnnotation: CreateItemAnnotation) => {
    this.setLoading(true);
    try {
      const response = await agent.Items.createAnnotation(itemAnnotation);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  deleteItemAnnotation = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Items.deleteAnnotation(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      return true;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
}
