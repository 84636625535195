import { Box, styled } from "@mui/material";
import React from "react";
import ViewerNavbar from "./navBar/ViewerNavbar";
import SideBar from "./sideBar/SideBar";
import ViewerSideBar from "./sideBar/ViewerSideBar";

interface Props {
  children: React.ReactNode;
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: "3rem",
  paddingRight: "3rem",
  transition: "all 0.3s",
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}));

// Main layout with top bar and sidebar navigation
const ViewerAppLayout = (props: Props) => {
  return (
    <React.Fragment>
      <ContentWrapper>
        <ViewerNavbar />
        {props.children}
      </ContentWrapper>
    </React.Fragment>
  );
};

export default ViewerAppLayout;
