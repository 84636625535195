import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "material-react-toastify";
import { store } from "../stores/store";
import { TokenData, UserLogin, ForgotPasswordRequest, ResetPasswordRequest } from "../models/auth";
import { User, RegisterUserRequest } from "../models/user";
import { ChangePasswordRequest, UpdateProfileRequest, UpdatePreferencesRequest, ChangeProfileImageRequest, CurrentUser } from "../models/currentUser";
import { ItemSearchParams, PartSearchParams, SearchParams } from "../models/searchParams";
import { PaginatedResult, Result } from "../models/responseWrappers";
import { CreateTenantRequest, Tenant } from "../models/tenant";

import { AddPartFamilyRequest, PartFamily } from "../models/partFamily";
import sleep from "app/utils/sleep";
import { AddPartKitRequest, AddPartRequest, Part, PartAsset, PartKit } from "app/models/part";
import { AddItemRequest, CreateItemAnnotation, Item, ItemAsset, UpdateItemAnnotation, UpdateItemPart } from "app/models/item";
import { AddRelationshipTypeRequest, RelationshipType } from "app/models/relationshipType";
import { AddItemPartRequest, ItemPart, ItemPartMapping, UpdateItemPartRequest } from "app/models/itemPart";
import { AddLocationItemRequest, AddLocationRequest, Location, UpdateLocationItem } from "app/models/location";
import { AddItemAssetRequest, AddPartAssetRequest, Asset } from "app/models/asset";
import { LocationItem } from "app/models/locationItem";
import {
  Facility,
  AddFacilityRequest,
  FacilityLocation,
  AddFacilityLocation,
  UpdateFacilityLocation,
  FacilitySearchResult,
  PartLookupViewer,
  UpdateFacilityPropMappings,
  UpdateFacilityAnnotation,
  FacilityAnnotation,
  CreateFacilityAnnotation,
} from "app/models/facility";
import { NavModel } from "app/models/nav";
import { CurrentStoreNavigator } from "app/models/currentNavigator";

// Base URL
// -- development: https://localhost:7250/api
// -- production: (your domain)
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Send up the token with every request, when there is a token
axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  config.headers = {
    Tenant: store.commonStore.tenant ?? "",
  };
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Axios reponse interceptors
axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000); // Artifical delay for development
    return response;
  },
  (error: AxiosError) => {
    // Basic error handling for 400 and 500 type errors
    const { data, status } = error.response!;
    switch (status) {
      case 400:
        toast.error("Error code 400: bad request");
        break;
      case 401:
        toast.error("Error code 401: unauthorized");
        store.currentUserStore.logout();
        break;
      case 500:
        toast.error("Error code 500: internal server error");
        console.log(data);
        break;
    }
    return Promise.reject(error);
  }
);
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// Axios Base
const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}, options?: {}) => axios.post<T>(url, body, options).then(responseBody),
  put: <T>(url: string, body: {}, options?: {}) => axios.put<T>(url, body, options).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

// Authentication & Profile Management (Current User)
const Account = {
  current: () => requests.get<Result<CurrentUser>>("/identity/profile"),
  login: (user: UserLogin) => requests.post<Result<TokenData>>(`/tokens`, user),
  update: (user: UpdateProfileRequest) => requests.put<Result<CurrentUser>>(`/identity/profile`, user),
  updateProfile: (user: UpdateProfileRequest) => requests.put<Result>(`/identity/profile`, user),
  changeProfileImage: (changeProfileImageRequest: ChangeProfileImageRequest) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(changeProfileImageRequest).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.put<Result<string>>("/identity/profile-image", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  updatePreferences: (updatePreferencesRequest: UpdatePreferencesRequest) => requests.put<Result>(`/identity/preferences`, updatePreferencesRequest),
  changePassword: (changePasswordRequest: ChangePasswordRequest) => requests.put<Result>(`/identity/change-password`, changePasswordRequest),
  forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) => requests.post<Result>(`/identity/forgot-password`, forgotPasswordRequest),
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) => requests.post<Result>(`/identity/reset-password`, resetPasswordRequest),
};

const Facilities = {
  search: (params: SearchParams) => requests.post<PaginatedResult<Facility>>(`/facilities/FacilityListPaginated`, params),
  create: (facility: AddFacilityRequest) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(facility).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.post<Result<Facility>>("/facilities", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  details: (id: string) => requests.get<Result<Facility>>(`/facilities/${id}`),
  detailNav: (id: string) => requests.get<Result<NavModel>>(`/facilities/${id}`),
  update: (facility: AddFacilityRequest) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(facility).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.put<Result<Facility>>(`/facilities/${facility.Id}`, formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  delete: (id: string) => requests.del<Result<string>>(`/facilities/${id}`),
  createLocation: (facility: AddFacilityLocation) => requests.post<Result<FacilityLocation>>("/facilities/locations", facility),
  updateLocation: (facilityLocation: UpdateFacilityLocation) =>
    requests.put<Result<FacilityLocation>>(`/facilities/locations/${facilityLocation.Id}`, facilityLocation),
  updateMappings: (facility: UpdateFacilityPropMappings) => requests.put<Result<Facility>>(`/facilities/${facility.Id}/mappings`, facility),
  createAnnotation: (facility: CreateFacilityAnnotation) => requests.post<Result<UpdateFacilityAnnotation>>("/facilities/annotations", facility),
  updateAnnotation: (facilityAnnotation: UpdateFacilityAnnotation) =>
    requests.put<Result<FacilityAnnotation>>(`/facilities/annotations/${facilityAnnotation.Id}`, facilityAnnotation),
  deleteAnnotation: (id: string) => requests.del<Result<string>>(`/facilities/annotations/${id}`),

  deleteFacilityLocation: (id: string) => requests.del<Result<string>>(`/facilities/locations/${id}`),
  getLocations: (id: string) => requests.get<Result<FacilityLocation[]>>(`/facilities/locations/${id}`),
};

// PartFamily
const PartFamilies = {
  list: () => requests.get<Result<PartFamily[]>>("/partFamilies/"),
  search: (params: SearchParams) => requests.post<PaginatedResult<PartFamily>>(`/partFamilies/PartFamilyListPaginated`, params), // paginated list handled server-side
  create: (partFamily: AddPartFamilyRequest) => requests.post<Result<PartFamily>>("/partFamilies", partFamily),
  details: (id: string) => requests.get<Result<PartFamily>>(`/partFamilies/${id}`),
  update: (partFamily: PartFamily) => requests.put<Result<PartFamily>>(`/partFamilies/${partFamily.id}`, partFamily),
  delete: (id: string) => requests.del<Result<string>>(`/partFamilies/${id}`),
};

// Part
const Parts = {
  search: (params: PartSearchParams) => requests.post<PaginatedResult<Part>>(`/parts/PartListPaginated`, params), // paginated list handled server-side
  list: () => requests.get<Result<Part[]>>("/parts/"),
  create: (part: AddPartRequest) => requests.post<Result<Part>>("/parts", part),
  details: (id: string) => requests.get<Result<Part>>(`/parts/${id}`),
  update: (part: Part) => requests.put<Result<Part>>(`/parts/${part.id}`, part),
  delete: (id: string) => requests.del<Result<string>>(`/parts/${id}`),
  get: (id: string) => requests.get<Result<Part>>(`/parts/${id}`),
  getAssets: (id: string) => requests.get<Result<PartAsset[]>>(`/parts/assets/${id}`),
  deletePartAsset: (id: string) => requests.del<Result<string>>(`/parts/assets/${id}`),
  getKitParts: (id: string) => requests.get<Result<PartKit[]>>(`/parts/kits/${id}`),
  createKitPart: (partKit: AddPartKitRequest) => requests.post<Result<PartKit>>("/parts/kits", partKit),
  deleteKitPart: (id: string) => requests.del<Result<string>>(`/parts/kits/${id}`),
  updateKitPart: (partKit: AddPartKitRequest) => requests.put<Result<PartKit>>(`/parts/kits/${partKit?.Id}`, partKit),
};

const Assets = {
  //list: () => requests.get<Result<Item[]>>("/items/"),
  //search: (params: SearchParams) => requests.post<PaginatedResult<Item>>(`/items/ItemListPaginated`, params), // paginated list handled server-side
  //create: (asset: AddAssetRequest) => requests.post<Result<Asset>>("/assets", asset),
  createPartAsset: (asset: AddPartAssetRequest) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(asset).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.put<Result<string>>("/assets/save-part-image", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  createItemAsset: (asset: AddItemAssetRequest) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(asset).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.put<Result<string>>("/assets/save-item-image", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  //details: (id: string) => requests.get<Result<Item>>(`/items/${id}`),
  //update: (item: Item) => requests.put<Result<Item>>(`/items/${item.id}`, item),
  //deletePartAsset: (id: string) => requests.del<Result<string>>(`/assets/delete-part-image/${id}`),
};

const Items = {
  list: () => requests.get<Result<Item[]>>("/items/"),
  search: (params: ItemSearchParams) => requests.post<PaginatedResult<Item>>(`/items/ItemListPaginated`, params), // paginated list handled server-side
  create: (item: Item) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(item).forEach(([key, val]) => {
      formData.append(key, val ?? "");
    });
    return requests.post<Result<Item>>("/items", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  update: (item: Item) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(item).forEach(([key, val]) => {
      formData.append(key, val ?? "");
    });
    return requests.put<Result<Item>>(`/items/${item.id}`, formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  details: (id: string) => requests.get<Result<Item>>(`/items/${id}`),
  delete: (id: string) => requests.del<Result<string>>(`/items/${id}`),
  get: (id: string) => requests.get<Result<Item>>(`/items/${id}`),
  getAssets: (id: string) => requests.get<Result<ItemAsset[]>>(`/items/assets/${id}`),
  createPart: (itemPart: AddItemPartRequest) => requests.post<Result<ItemPart>>("/items/createPart", itemPart),
  deletePart: (id: string) => requests.del<Result<string>>(`/items/parts/${id}`),
  updateFullPart: (itemPart: UpdateItemPartRequest) => requests.post<Result<ItemPart>>("/items/updatePart", itemPart),
  getParts: (id: string) => requests.get<Result<ItemPart[]>>(`/items/parts/${id}`),
  updatePart: (item: UpdateItemPart) => requests.put<Result<ItemPart>>(`/items/partsMapping/${item.Id}`, item),
  createAnnotation: (createItemAnnotation: CreateItemAnnotation) =>
    requests.post<Result<UpdateItemAnnotation>>("/items/annotations", createItemAnnotation),
  updateAnnotation: (updateItemAnnotation: UpdateItemAnnotation) =>
    requests.put<Result<UpdateItemAnnotation>>(`/items/annotations/${updateItemAnnotation.Id}`, updateItemAnnotation),
  deleteAnnotation: (id: string) => requests.del<Result<string>>(`/items/annotations/${id}`),
};

const ItemParts = {
  list: () => requests.get<Result<ItemPart[]>>("/itemParts/"),
  search: (params: SearchParams) => requests.post<PaginatedResult<ItemPart>>(`/itemParts/ItemPartListPaginated`, params), // paginated list handled server-side
  create: (item: AddItemPartRequest) => requests.post<Result<ItemPart>>("/itemParts", item),
  details: (id: string) => requests.get<Result<ItemPart>>(`/itemParts/${id}`),
  update: (item: ItemPart) => requests.put<Result<ItemPart>>(`/itemParts/${item.id}`, item),
  delete: (id: string) => requests.del<Result<string>>(`/itemParts/${id}`),
};

const StoreNavigator = {
  details: (id: string | null = "") => requests.get<Result<CurrentStoreNavigator>>(`/storeNavigator/${id}`),
  search: (searchText: string | null = "") => requests.get<Result<FacilitySearchResult>>(`/storeNavigator/search/${searchText}`),
  partMappings: (id: string) => requests.get<Result<PartLookupViewer>>(`/storeNavigator/part/${id}`),
};

const Locations = {
  list: () => requests.get<Result<Location[]>>("/locations/"),
  search: (params: SearchParams) => requests.post<PaginatedResult<Location>>(`/locations/LocationListPaginated`, params),
  create: (location: AddLocationRequest) => requests.post<Result<Location>>("/locations", location),
  details: (id: string) => requests.get<Result<Location>>(`/locations/${id}`),
  update: (location: Location) => {
    let formData = new FormData(); // form data to send up a file
    Object.entries(location).forEach(([key, val]) => {
      formData.append(key, val);
    });
    return requests.put<Result<Location>>(`/locations/${location.id}`, formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  updateItem: (locationItem: UpdateLocationItem) => requests.put<Result<LocationItem>>(`/locations/items/${locationItem.Id}`, locationItem),
  deleteItem: (id: string) => requests.del<Result<string>>(`/locations/items/${id}`),
  delete: (id: string) => requests.del<Result<string>>(`/locations/${id}`),
  getItems: (id: string) => requests.get<Result<LocationItem[]>>(`/locations/items/${id}`),
  createItem: (locationItem: AddLocationItemRequest) => requests.post<Result<LocationItem>>("/locations/createItem", locationItem),
};

const RelationshipTypes = {
  list: () => requests.get<Result<RelationshipType[]>>("/relationshipTypes/"),
  search: (params: SearchParams) => requests.post<PaginatedResult<RelationshipType>>(`/relationshipTypes/RelationshipTypeListPaginated`, params), // paginated list handled server-side
  create: (relationshipType: AddRelationshipTypeRequest) => requests.post<Result<RelationshipType>>("/relationshipTypes", relationshipType),
  details: (id: string) => requests.get<Result<RelationshipType>>(`/relationshipTypes/${id}`),
  update: (relationshipType: RelationshipType) =>
    requests.put<Result<RelationshipType>>(`/relationshipTypes/${relationshipType.id}`, relationshipType),
  delete: (id: string) => requests.del<Result<string>>(`/relationshipTypes/${id}`),
};

// User Management
const Users = {
  list: () => requests.get<Result<User[]>>("/identity/"), // full list for client-side pagination
  create: (appUser: RegisterUserRequest) => requests.post<Result<User>>(`/identity/register`, appUser),
  details: (id: string) => requests.get<Result<User>>(`/identity/user/${id}`),
  update: (user: User) => requests.put<Result<User>>(`/identity/user/${user.id}`, user),
  delete: (id: string) => requests.del<Result<string>>(`/identity/user/${id}`),
};

// Tenant Management
const Tenants = {
  list: () => requests.get<Result<Tenant[]>>("/tenants"), // full list for client-side pagination
  details: (id: string) => requests.get<Result<Tenant>>(`/tenants/${id}`),
  create: (tenant: CreateTenantRequest) => requests.post<Result<Tenant>>(`/tenants`, tenant),
  update: (tenant: Tenant) => requests.put<Result<Tenant>>(`/tenants/`, tenant),
};

const agent = {
  Account,
  Users,
  Tenants,
  PartFamilies,
  Parts,
  Items,
  RelationshipTypes,
  ItemParts,
  Locations,
  Assets,
  Facilities,
  StoreNavigator,
};

export default agent;
